<template>
  <div class="__timecard">
    <TimecardLogo title="勤怠入力"></TimecardLogo>
    <!-- <div class="row mt-5">
      <div class="__time">12</div>
      <label for="">:</label>
      <div class="__time">23</div>
      <label for="">:</label>
      <div class="__time">09</div>
    </div> -->
    <div class="user-info mt-5">
      <div class="user-info-row">
        <span>ID</span>
        <span>{{ formattedId }}</span>
      </div>
      <div class="user-info-row">
        <span>名前</span>
        <span>{{ data.name }}</span>
      </div>
      <div class="user-info-row">
        <span>店舗</span>
        <span>{{ data.shop }}</span>
      </div>
      <div class="user-info-row">
        <span>時給</span>
        <span>{{ data.hourly_wage }}円</span>
      </div>
      <div class="user-info-row">
        <span>勤務時間</span>
        <span><span>{{ formattedStartTime }} ~ {{ formattedStopTime }}</span></span>
      </div>
      <div class="user-info-row">
        <span>休憩時間</span>
        <span>{{ formattedBreakTime }}</span>
      </div>
      <div class="user-info-row">
        <span>有給残日数</span>
        <span>{{ data.remaining_paid_leave_days }}日</span>
      </div>
      <div class="user-info-row">
        <span>メールアドレス</span>
        <span>{{ user.email }}</span>
      </div>
      <div class="user-info-row">
        <span>パスワード</span>
        <span>●●●●●●●●●●●●</span>
      </div>
    </div>
    <button class="logout-button" @click="logout">ログアウト</button>
  </div>
</template>
<script>
import TimecardLogo from '../../components/TimecardLogo';
export default {
  data () {
    return {
      data: [],
      user: [],
    }
  },
  mounted() {
    this.getUserInfo();
  },
  computed: {
    formattedId() {
      return this.data.id ? this.data.id.toString().padStart(10, '0') : '0000000000';
    },
    formattedStartTime() {
      return this.data.regular_working_hours_start ? this.data.regular_working_hours_start.slice(0, 5) : '00:00';
    },
    formattedStopTime() {
      return this.data.regular_working_hours_start ? this.data.regular_working_hours_stop.slice(0, 5) : '00:00';
    },
    formattedBreakTime() {
      const hours = this.data.break_time ? this.data.break_time.toString().padStart(2, '0') : '00';
      return `${hours}:00`;
    }
  },
  methods: {
    getUserInfo () {
      this.$http.get(process.env.VUE_APP_API_PATH + '/staff/shop/0/me')
      .then(response => {
        if (response.data.success) {
          this.data = response.data.data.staff;
          this.user = response.data.data;
          this.data.shop = this.$store.getters.shop_name;
        }
      })
      .catch(error => {
        if (error.response) {
          this.$toast.error(error.response.data.message);
        }
      });
    },
    logout () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push({ name: 'TimecardLogin' });
      })
    },
  },
  components: { TimecardLogo }
}
</script>

<style scoped>
.user-info {
  margin-bottom: 20px;
  width: 300px;
}

.user-info-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.user-info-row span {
  font-size: 18px;
  color: #333;
}

.logout-button {
  width: 150px;
  padding: 10px 0;
  background-color: #008000;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #006400;
}
</style>